import { IIconProps, MessageBarType } from '@fluentui/react';
import { Divider } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { OperatingSystemFamily } from '../../../constants/browser';
import { CustomizationFailureRetainDays } from '../../../constants/customization';
import { DevBoxFailureReason, OSType } from '../../../constants/dev-box';
import { FeatureFlagName } from '../../../constants/features';
import { ExtendedIcon } from '../../../icons/initialize-extended-icons';
import { CustomizationFailure, CustomizationGroupStatus } from '../../../models/customization';
import { DevBoxRepairOperationResult, FailureOnDevBox } from '../../../models/dev-box';
import { HibernateSupport } from '../../../models/pool';
import { isAsyncStateSuccess } from '../../../redux/selector/common';
import { AsyncState } from '../../../redux/store/common-state';
import { DevBoxState } from '../../../redux/store/dev-box-state';
import {
    getOverallCustomizationGroupStatus,
    isFailedCustomizationGroupStatus,
} from '../../../utilities/customization-group';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { getDaysBetweenDates, getTimeStringFromDate, isDayAfterNextDay, isNextDay } from '../../../utilities/time';
import { FluentIconNames } from '../../common/fluent-icon-names';
import { OldMetadataItemViewModel } from '../../common/metadata/models';
import { ErrorBannerActionProps } from '../../resource-card/error-banner-actions';
import {
    ResourceCardBannerKind,
    ResourceCardBannerViewModel,
    ResourceCardError,
    ResourceCardErrorSeverity,
} from '../../resource-card/models';
import { ScheduleMetadata } from '../dev-box-metadata/dev-box-metadata';
import { DevBoxActionState, DevBoxActionViewModelErrorCode, DevBoxViewModel } from '../models';
import DevBoxBannerInfoMessage from './dev-box-banner-info-message';
import DevBoxBannerSuccessMessage from './dev-box-banner-success-message';
import { sharedMessages } from './messages';
import {
    DevBoxCardInfoBannerMessageKind,
    DevBoxCardSuccessBannerMessageKind,
    DevBoxStatusImageKind,
    RepairOperationResultOutcome,
} from './models';

const unknownFailureFormattedMessage = (
    <FormattedMessage
        id="ErrorBanner_UnknownOperationMessage_Text"
        defaultMessage="This dev box is in a failed state. Please delete and recreate it, or contact your admin."
        description="Failure message informing the user that an unknown operation has failed for a dev box."
    />
);

const issuesCouldNotBeFixedFormattedMessage = (
    <FormattedMessage
        id="ErrorBanner_RepairOperationIssuesDetectedNotFixed_Text"
        defaultMessage="An issue was detected but could not be fixed automatically."
        description="Message informing the user that an issue was detected but could not be repaired automatically for a dev box."
    />
);

const customizationFailedFormattedMessage = (
    <FormattedMessage
        id="ErrorBanner_CreateCustomizationGroupFailed_Text"
        defaultMessage="Your dev box has been created, but we encountered errors while applying customizations."
        description="Failure message informing the user that customizing the dev box failed."
    />
);

const createCustomizationGroupFailedFormattedMessage = (
    <FormattedMessage
        id="ErrorBanner_CustomizationGroupCreationFailed_Text"
        defaultMessage="We encountered errors while applying customizations."
        description="Failure message informing the user that customizations failed."
    />
);

const getFormattedMessageForFailure = (failureOnDevBox: FailureOnDevBox): React.ReactNode => {
    const { reason } = failureOnDevBox;

    switch (reason) {
        case DevBoxFailureReason.CreateFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_CreateOperationMessage_Text"
                    defaultMessage="Provisioning of this dev box failed. Please delete and recreate it, or contact your admin."
                    description="Failure message informing the user that creating (a.k.a. provisioning) the dev box failed."
                />
            );
        case DevBoxFailureReason.DeleteFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_DeleteOperationMessage_Text"
                    defaultMessage="The delete operation failed on this dev box. Please try again."
                    description="Failure message informing the user that deleting the dev box failed."
                />
            );
        case DevBoxFailureReason.GetRemoteConnectionFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_GetRemoteConnectionOperationMessage_Text"
                    defaultMessage="The request to get Remote Desktop links for this dev box failed."
                    description="Failure message informing the user that we failed to get remote connection information for a dev box."
                />
            );
        case DevBoxFailureReason.HibernateFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_HibernateOperationMessage_Text"
                    defaultMessage="The hibernate operation failed on this dev box. Please try again."
                    description="Failure message informing the user that hibernating the dev box failed."
                />
            );
        case DevBoxFailureReason.RestartFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_RestartOperationMessage_Text"
                    defaultMessage="The restart operation failed on this dev box. Please try again."
                    description="Error message informing the user that restarting the dev box failed."
                />
            );
        case DevBoxFailureReason.StartFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_StartOperationMessage_Text"
                    defaultMessage="The start operation failed on this dev box. Please try again."
                    description="Failure message informing the user that starting the dev box failed."
                />
            );
        case DevBoxFailureReason.ShutdownFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_StopOperationMessage_Text"
                    defaultMessage="The shutdown operation failed on this dev box. Please try again."
                    description="Failure message informing the user that shutting down the dev box failed."
                />
            );
        case DevBoxFailureReason.RepairFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_RepairOperationMessage_Text"
                    defaultMessage="The repair operation failed on this dev box. Please try again."
                    description="Failure message informing the user that repairing the dev box failed."
                />
            );
        case DevBoxFailureReason.RestoreFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_RestoreOperationMessage_Text"
                    defaultMessage="The restore operation failed on this dev box. Please try again."
                    description="Failure message informing the user that restoring the dev box failed."
                />
            );
        case DevBoxFailureReason.CustomizeFailed:
            return (
                <FormattedMessage
                    id="ErrorBanner_CustomizeOperationMessage_Text"
                    defaultMessage="The customize operation failed on this dev box."
                    description="Failure message informing the user that customizing the dev box failed."
                />
            );
        default:
            return unknownFailureFormattedMessage;
    }
};

// At present, we only allow the option to retry the "get remote connection" operation, as there is no
// other way for the user to initiate the request without refreshing.
const getIsFailureRetryable = (failureOnDevBox: FailureOnDevBox): boolean =>
    failureOnDevBox.reason === DevBoxFailureReason.GetRemoteConnectionFailed;

const getSeverityForFailure = (failureOnDevBox: FailureOnDevBox): ResourceCardErrorSeverity => {
    const { reason } = failureOnDevBox;

    switch (reason) {
        case DevBoxFailureReason.DelayFailed:
        case DevBoxFailureReason.DeleteFailed:
        case DevBoxFailureReason.GetRemoteConnectionFailed:
        case DevBoxFailureReason.HibernateFailed:
        case DevBoxFailureReason.RestartFailed:
        case DevBoxFailureReason.SkipFailed:
        case DevBoxFailureReason.StartFailed:
        case DevBoxFailureReason.RepairFailed:
        case DevBoxFailureReason.RestoreFailed:
        case DevBoxFailureReason.ShutdownFailed:
        case DevBoxFailureReason.CustomizeFailed:
            return ResourceCardErrorSeverity.Low;

        case DevBoxFailureReason.CreateFailed:
        case DevBoxFailureReason.ProvisionedWithWarning:
        case DevBoxFailureReason.ProvisioningFailed:
        default:
            return ResourceCardErrorSeverity.High;
    }
};

const inFailedOrErrorState = (state: AsyncState): boolean => state === AsyncState.Failed || state === AsyncState.Error;
const inPartialSuccessState = (state: AsyncState): boolean => state === AsyncState.PartialSuccess;

export const getAreActionsDisabled = (devBoxState: DevBoxState): boolean => {
    switch (devBoxState) {
        case DevBoxState.Deleting:
        case DevBoxState.Starting:
        case DevBoxState.Stopping:
        case DevBoxState.Hibernating:
        case DevBoxState.Resuming:
        case DevBoxState.Restarting:
        case DevBoxState.Repairing:
        case DevBoxState.Restoring:
            return true;
        default:
            return false;
    }
};

const getFormattedMessageForSkipFailure = (
    code: DevBoxActionViewModelErrorCode,
    _hasHibernateSupport: boolean
): React.ReactNode => {
    switch (code) {
        case DevBoxActionViewModelErrorCode.DevBoxActionAlreadyComplete:
            return (
                <FormattedMessage
                    id="ErrorBanner_Skip_Shutdown_ActionAlreadyComplete"
                    defaultMessage="Skip could not complete, because shutdown is already in progress."
                    description="Failure message informing the user that their request to skip a scheduled shutdown failed because the shutdown has already happened."
                />
            );
        case DevBoxActionViewModelErrorCode.DevBoxActionPreferenceUpdateViolatesThreshold:
            return (
                <FormattedMessage
                    id="ErrorBanner_Skip_Shutdown_ActionOccuringInNextFiveMinutes"
                    defaultMessage="Skip could not complete, a shutdown scheduled in the next 5 minutes cannot be skipped or delayed."
                    description="Failure message informing the user that their request to skip a scheduled shutdown failed because the shutdown will occur in the next 5 minutes."
                />
            );
        case DevBoxActionViewModelErrorCode.DevBoxActionAlreadySkipped:
            return (
                <FormattedMessage
                    id="ErrorBanner_Skip_Shutdown_ActionAlreadySkipped"
                    defaultMessage="Skip could not complete, this shutdown has already been skipped."
                    description="Failure message informing the user that their request to skip a scheduled shutdown failed because the shutdown has already been skipped."
                />
            );

        case DevBoxActionViewModelErrorCode.GenericError:
        default:
            return (
                <FormattedMessage
                    id="ErrorBanner_Skip_Shutdown_Default"
                    defaultMessage="Shutdown could not be skipped at this time. Please try again."
                    description="Failure message informing the user that their request to skip a scheduled shutdown failed."
                />
            );
    }
};

const getFormattedMessageForDelayFailure = (
    code: DevBoxActionViewModelErrorCode,
    _hasHibernateSupport: boolean
): React.ReactNode => {
    switch (code) {
        case DevBoxActionViewModelErrorCode.DevBoxActionAlreadyComplete:
            return (
                <FormattedMessage
                    id="ErrorBanner_Delay_Shutdown_ActionAlreadyComplete"
                    defaultMessage="Delay could not complete, because shutdown is already in progress."
                    description="Failure message informing the user that their request to delay a scheduled shutdown failed because the shutdown has already happened."
                />
            );
        case DevBoxActionViewModelErrorCode.DevBoxActionPreferenceUpdateViolatesThreshold:
            return (
                <FormattedMessage
                    id="ErrorBanner_Delay_Shutdown_ActionOccuringInNextFiveMinutes"
                    defaultMessage="Delay could not complete, a shutdown scheduled in the next 5 minutes cannot be skipped or delayed."
                    description="Failure message informing the user that their request to delay a scheduled shutdown failed because the shutdown will occur in the next 5 minutes."
                />
            );
        case DevBoxActionViewModelErrorCode.DevBoxActionAlreadySkipped:
            return (
                <FormattedMessage
                    id="ErrorBanner_Delay_Shutdown_ActionAlreadySkipped"
                    defaultMessage="Delay could not complete, this shutdown has already been skipped."
                    description="Failure message informing the user that their request to delay a scheduled shutdown failed because the shutdown has already been skipped."
                />
            );

        case DevBoxActionViewModelErrorCode.GenericError:
        default:
            return (
                <FormattedMessage
                    id="ErrorBanner_Delay_Shutdown_Default"
                    defaultMessage="Shutdown could not be delayed at this time. Please try again."
                    description="Failure message informing the user that their request to delay a scheduled shutdown failed."
                />
            );
    }
};

/** For partial errors, treat any error code at indicates an imminent shutdown as an error */
const isFailureLevelPartialSuccessCode = (code: DevBoxActionViewModelErrorCode | undefined): boolean =>
    code === DevBoxActionViewModelErrorCode.DevBoxActionAlreadyComplete ||
    code === DevBoxActionViewModelErrorCode.DevBoxActionPreferenceUpdateViolatesThreshold;

const getFormattedMessageForSkipPartialSuccess = (_hasHibernateSupport: boolean): React.ReactNode => {
    return (
        <FormattedMessage
            id="ErrorBanner_Skip_Shutdown_PartialSuccess"
            defaultMessage="We could not complete the requested skip, but the shutdown schedule has been updated to the closest available time."
            description="Failure message informing the user that we could only partially complete their skip shutdown request"
        />
    );
};

const getFormattedMessageForDelayPartialSuccess = (_hasHibernateSupport: boolean): React.ReactNode => {
    return (
        <FormattedMessage
            id="ErrorBanner_Delay_Shutdown_PartialSuccess"
            defaultMessage="We could not complete the requested delay, but the shutdown schedule has been updated to the closest available time."
            description="Failure message informing the user that we could only partially complete their delay shutdown request"
        />
    );
};

const getScheduleDevBoxActionError = (
    devBoxActionState: DevBoxActionState,
    hasHibernateSupport: boolean
): ResourceCardError | undefined => {
    const { delayState, skipState } = devBoxActionState;
    let { delayErrorCode, skipErrorCode } = devBoxActionState;

    if (
        inFailedOrErrorState(delayState) ||
        (inPartialSuccessState(delayState) && isFailureLevelPartialSuccessCode(delayErrorCode))
    ) {
        delayErrorCode = delayErrorCode ?? DevBoxActionViewModelErrorCode.GenericError;

        return {
            message: getFormattedMessageForDelayFailure(delayErrorCode, hasHibernateSupport),
            severity: ResourceCardErrorSeverity.High,
        };
    }

    if (delayState === AsyncState.PartialSuccess) {
        delayErrorCode = delayErrorCode ?? DevBoxActionViewModelErrorCode.GenericError;

        return {
            message: getFormattedMessageForDelayPartialSuccess(hasHibernateSupport),
            severity: ResourceCardErrorSeverity.Low,
        };
    }

    if (
        inFailedOrErrorState(skipState) ||
        (inPartialSuccessState(skipState) && isFailureLevelPartialSuccessCode(skipErrorCode))
    ) {
        skipErrorCode = skipErrorCode ?? DevBoxActionViewModelErrorCode.GenericError;

        return {
            message: getFormattedMessageForSkipFailure(skipErrorCode, hasHibernateSupport),
            severity: ResourceCardErrorSeverity.High,
        };
    }

    if (skipState === AsyncState.PartialSuccess) {
        skipErrorCode = skipErrorCode ?? DevBoxActionViewModelErrorCode.GenericError;

        return {
            message: getFormattedMessageForSkipPartialSuccess(hasHibernateSupport),
            severity: ResourceCardErrorSeverity.Low,
        };
    }

    return undefined;
};

export const getAriaLabelMessageDescriptorForOSType = (osType: OSType | undefined): MessageDescriptor => {
    switch (osType) {
        case OSType.Windows:
        default:
            return sharedMessages.windowsIconAriaLabel;
    }
};

export const getIconForOSType = (osType: OSType | undefined): string => {
    switch (osType) {
        case OSType.Windows:
        default:
            return ExtendedIcon.MicrosoftLogo;
    }
};

export const getDevBoxCardMetadata = (
    osTypeMetadata: OldMetadataItemViewModel,
    state: DevBoxState,
    scheduleMetadata: OldMetadataItemViewModel | undefined
): OldMetadataItemViewModel[] => {
    if (state === DevBoxState.Creating) {
        return [osTypeMetadata];
    }

    const metadata: OldMetadataItemViewModel[] = [osTypeMetadata];
    if (scheduleMetadata) {
        metadata.push(scheduleMetadata);
    }

    return metadata;
};

export const getPrimaryActions = (
    openInBrowserMenuItem: JSX.Element,
    openInRdpClientMenuItem: JSX.Element,
    operatingSystemFamily: OperatingSystemFamily,
    state: DevBoxState
): JSX.Element[] => {
    if (
        state === DevBoxState.Creating ||
        state === DevBoxState.Failed ||
        state === DevBoxState.Unknown ||
        state === DevBoxState.Repairing ||
        state === DevBoxState.Restoring
    ) {
        return [];
    }

    if (operatingSystemFamily === OperatingSystemFamily.Windows) {
        return [openInRdpClientMenuItem, openInBrowserMenuItem];
    }

    return [openInBrowserMenuItem];
};

export const getResourceCardError = (
    failureOnDevBox: FailureOnDevBox | undefined,
    state: DevBoxState,
    hasHibernateSupport: boolean,
    devBoxActionViewModel?: DevBoxActionState,
    repairOperationResult?: DevBoxRepairOperationResult,
    customizationStatus?: CustomizationGroupStatus,
    customizationFailure?: CustomizationFailure,
    latestCustomizationGroupFailureEndTime?: Date
): ResourceCardError | undefined => {
    // If there's an error on the dev box (action or provisioning), show that
    if (failureOnDevBox) {
        const { failure } = failureOnDevBox;

        return {
            message: getFormattedMessageForFailure(failureOnDevBox),
            failure,
            retryable: getIsFailureRetryable(failureOnDevBox),
            severity: getSeverityForFailure(failureOnDevBox),
        };
    }

    // Catch cases where resource is missing error but is in a failed state
    if (state === DevBoxState.Failed) {
        return {
            message: unknownFailureFormattedMessage,
            severity: ResourceCardErrorSeverity.High,
        };
    }

    // If there's an error on a dev box's dev box action, show that
    const devBoxActionError = devBoxActionViewModel
        ? getScheduleDevBoxActionError(devBoxActionViewModel, hasHibernateSupport)
        : undefined;

    if (devBoxActionError) {
        return devBoxActionError;
    }

    if (repairOperationResult?.repairOutcome === RepairOperationResultOutcome.IssuesDetected) {
        return {
            message: issuesCouldNotBeFixedFormattedMessage,
            severity: ResourceCardErrorSeverity.Med,
            iconProps: { iconName: FluentIconNames.Info },
        };
    }

    if (customizationFailure) {
        const { failure } = customizationFailure;

        return {
            message: createCustomizationGroupFailedFormattedMessage,
            failure,
            severity: ResourceCardErrorSeverity.Low,
        };
    }

    if (shouldDisplayCustomizationFailure(customizationStatus, latestCustomizationGroupFailureEndTime)) {
        return {
            message: customizationFailedFormattedMessage,
            severity: ResourceCardErrorSeverity.Low,
        };
    }

    return undefined;
};

const actionableDevBoxStates: DevBoxState[] = [DevBoxState.Running, DevBoxState.Stopped, DevBoxState.Hibernated];

export const getSecondaryActions = (
    deleteMenuItem: JSX.Element,
    startMenuItem: JSX.Element,
    state: DevBoxState,
    devBoxDetailsMenuItem: JSX.Element,
    canDelayShutdown: boolean,
    delayShutdownMenuItem: JSX.Element,
    restoreMenuItem: JSX.Element,
    hasHibernateSupport: boolean,
    hibernateMenuItem: JSX.Element,
    resumeMenuItem: JSX.Element,
    shutdownMenuItem: JSX.Element,
    restartMenuItem: JSX.Element,
    repairMenuItem: JSX.Element,
    customizeMenuItem: JSX.Element,
    hasCustomizationGroup: boolean,
    devBoxSupportMenuItem: JSX.Element,
    isInMicrosoftTenant: boolean
): JSX.Element[] => {
    if (state === DevBoxState.Deleting) {
        return [];
    }

    if (state === DevBoxState.Repairing || state === DevBoxState.Restoring) {
        return [devBoxDetailsMenuItem];
    }

    if (!actionableDevBoxStates.includes(state)) {
        return [deleteMenuItem];
    }

    const menuItems = [];
    // Start/stop section
    switch (state) {
        case DevBoxState.Running:
            // Task #1747353 to remove feature flag when hibernate/hibernated states are enabled
            // Also remove references to stop when completing this task
            if (hasHibernateSupport) {
                menuItems.push(hibernateMenuItem);
            }

            menuItems.push(shutdownMenuItem, restartMenuItem, <Divider />);
            break;
        case DevBoxState.Hibernated:
            menuItems.push(resumeMenuItem, shutdownMenuItem, restartMenuItem, <Divider />);
            break;
        case DevBoxState.Stopped:
            menuItems.push(startMenuItem, <Divider />);
            break;
        default:
            break;
    }

    // Skip/delay shutdown section
    if (canDelayShutdown) {
        menuItems.push(delayShutdownMenuItem, <Divider />);
    }

    // Snapshot/restore section
    if (isInMicrosoftTenant) {
        menuItems.push(restoreMenuItem, <Divider />);
    }

    // Troubleshooting section
    if (hasCustomizationGroup) {
        menuItems.push(customizeMenuItem);
    }

    menuItems.push(devBoxDetailsMenuItem, repairMenuItem, devBoxSupportMenuItem, <Divider />);

    // Delete section
    menuItems.push(deleteMenuItem);

    return menuItems;
};

export const getShowStatusImage = (devBoxState: DevBoxState): boolean => {
    switch (devBoxState) {
        case DevBoxState.Running:
        case DevBoxState.Starting:
        case DevBoxState.Resuming:
        case DevBoxState.Stopped:
        case DevBoxState.Stopping:
        case DevBoxState.Hibernated:
        case DevBoxState.Hibernating:
        case DevBoxState.Restarting:
        case DevBoxState.Repairing:
        case DevBoxState.Restoring:
            return true;
        default:
            return false;
    }
};

export const getStatusImageKind = (devBoxState: DevBoxState): DevBoxStatusImageKind => {
    switch (devBoxState) {
        case DevBoxState.Hibernated:
        case DevBoxState.Resuming:
        case DevBoxState.Starting:
        case DevBoxState.Stopped:
            return DevBoxStatusImageKind.Stopped;

        case DevBoxState.Hibernating:
        case DevBoxState.Restarting:
        case DevBoxState.Stopping:
            return DevBoxStatusImageKind.Stopping;

        default:
            return DevBoxStatusImageKind.Running;
    }
};

export const getUseTranslucentPreviewImage = (devBoxState: DevBoxState): boolean => {
    switch (devBoxState) {
        case DevBoxState.Creating:
        case DevBoxState.Deleting:
            return true;
        default:
            return false;
    }
};

export const getScheduleShutdownMetadataViewModel = (
    locale: string,
    formatMessage: (message: MessageDescriptor) => string,
    nextScheduledStopTime: Date | undefined,
    hasHibernateSupport: boolean
): OldMetadataItemViewModel | undefined => {
    // Do not display anything if no next scheduled time
    if (nextScheduledStopTime) {
        // Note: Needing to define this component as a variable due to an issue with react and typescript formatting/usage.
        // In a tsx file when we have a react node such as <div> we are essentially calling React.createComponent underneath. Due to this, we need to import 'React' for runtime compilation purposes..
        // However, if we do not explicitly use the 'React' import anywhere the import will be removed.
        // Here are more details surrounding the same issue: https://github.com/microsoft/TypeScript/issues/49486.
        const scheduleComponent: React.ReactNode = (
            <ScheduleMetadata
                locale={locale}
                scheduledTime={nextScheduledStopTime}
                hasHibernateSupport={hasHibernateSupport}
            />
        );

        return {
            description: formatMessage(sharedMessages.scheduleIconAriaLabel),
            icon: 'Clock',
            key: 'schedule',
            primary: false,
            value: scheduleComponent,
        };
    }

    return undefined;
};

// TODO (Task #1759060): once we have accurate error codes for hibernate, implement this function
export const getAdditionalErrorBannerActions = (
    _failureOnDevBox: FailureOnDevBox | undefined
): ErrorBannerActionProps[] => {
    return [];
};

export const getRepairDismissableContentId = (resourceId: string, operationId?: string): string | undefined => {
    return operationId ? `RepairBanner;${resourceId};${operationId}` : undefined;
};

export const getDevBoxCardInfoBannerMessageKind = (
    devBoxActionState: DevBoxActionState,
    state: DevBoxState,
    status: CustomizationGroupStatus | undefined,
    repairOperationResult: DevBoxRepairOperationResult | undefined
): DevBoxCardInfoBannerMessageKind => {
    if (state === DevBoxState.Repairing) {
        return DevBoxCardInfoBannerMessageKind.RepairOperationInProgressMessage;
    }

    if (state === DevBoxState.Restoring) {
        return DevBoxCardInfoBannerMessageKind.RestoreOperationInProgressMessage;
    }

    if (repairOperationResult?.repairOutcome === RepairOperationResultOutcome.NoIssuesDetected) {
        return DevBoxCardInfoBannerMessageKind.NoIssuesDetectedMessage;
    }

    if (status === CustomizationGroupStatus.Running || status === CustomizationGroupStatus.NotStarted) {
        return DevBoxCardInfoBannerMessageKind.CustomizeOperationInProgressMessage;
    }

    if (
        isFeatureFlagEnabled(FeatureFlagName.EnableOffloadDevBox) &&
        devBoxActionState.scheduledDeleteTime !== undefined
    ) {
        return DevBoxCardInfoBannerMessageKind.OffloadDevBoxMessage;
    }

    return DevBoxCardInfoBannerMessageKind.None;
};

export const getDevBoxCardSuccessBannerMessageKind = (
    devBoxActionState: DevBoxActionState,
    locale: string,
    _hasHibernateSupport: boolean,
    repairOperationResult?: DevBoxRepairOperationResult
): DevBoxCardSuccessBannerMessageKind => {
    const { delayState, skipState, nextScheduledStopTime } = devBoxActionState;

    if (isAsyncStateSuccess(delayState)) {
        const updatedScheduleTime = getTimeStringFromDate(nextScheduledStopTime, locale);

        if (nextScheduledStopTime && updatedScheduleTime) {
            const currentTime = new Date();

            const isTomorrow = isNextDay(currentTime, nextScheduledStopTime);

            if (isTomorrow) {
                return DevBoxCardSuccessBannerMessageKind.DelayStateNextDaySuccessMessage;
            }

            const isDayAfterNext = isDayAfterNextDay(currentTime, nextScheduledStopTime);

            if (isDayAfterNext) {
                return DevBoxCardSuccessBannerMessageKind.DelayStateDayAfterNextSuccessMessage;
            }

            return DevBoxCardSuccessBannerMessageKind.DelayStateSuccessMessage;
        }
    }

    if (isAsyncStateSuccess(skipState)) {
        return DevBoxCardSuccessBannerMessageKind.SkipStateSuccessMessage;
    }

    if (repairOperationResult?.repairOutcome === RepairOperationResultOutcome.FixApplied) {
        return DevBoxCardSuccessBannerMessageKind.IssuesFixedMessage;
    }

    return DevBoxCardSuccessBannerMessageKind.None;
};

export const getDevBoxCardBannerViewModel = (
    devBox: DevBoxViewModel,
    locale: string,
    iconProps?: IIconProps,
    spinnerAriaLabel?: string,
    error?: ResourceCardError,
    onRetryFailedOperation?: () => void,
    onSeeErrorDetails?: () => void,
    onSeeCustomizationDetails?: () => void,
    onDismiss?: () => void
): ResourceCardBannerViewModel | undefined => {
    const { customizationGroups, failureOnDevBox, resource, state, devBoxActionState, repairOperation } = devBox;
    const { hibernateSupport } = resource;

    const status = getOverallCustomizationGroupStatus(customizationGroups);

    const { result: repairOperationResult } = repairOperation ?? {};

    const hasHibernateSupport = hibernateSupport === HibernateSupport.Enabled;

    if (error) {
        const isDismissable =
            repairOperationResult !== undefined || failureOnDevBox?.reason === DevBoxFailureReason.CustomizeFailed;

        return {
            kind: ResourceCardBannerKind.Error,
            error,
            isDismissable,
            additionalErrorBannerActions: getAdditionalErrorBannerActions(failureOnDevBox),
            onRetryFailedOperation,
            onSeeErrorDetails,
            onDismiss,
        };
    }

    const successBannerKind = getDevBoxCardSuccessBannerMessageKind(
        devBoxActionState,
        locale,
        hasHibernateSupport,
        repairOperationResult
    );
    const infoBannerKind = getDevBoxCardInfoBannerMessageKind(devBoxActionState, state, status, repairOperationResult);

    if (successBannerKind !== DevBoxCardSuccessBannerMessageKind.None) {
        const isDismissable = successBannerKind === DevBoxCardSuccessBannerMessageKind.IssuesFixedMessage;

        const successBannerMessage = (
            <DevBoxBannerSuccessMessage
                kind={successBannerKind}
                locale={locale}
                devBoxActionState={devBoxActionState}
            />
        );

        return {
            kind: ResourceCardBannerKind.Info,
            isDismissable,
            bannerMessage: successBannerMessage,
            onDismiss,
            messageBarType: MessageBarType.success,
        };
    }

    if (infoBannerKind !== DevBoxCardInfoBannerMessageKind.None) {
        const isDismissable = infoBannerKind === DevBoxCardInfoBannerMessageKind.NoIssuesDetectedMessage;
        const messageBarType =
            infoBannerKind === DevBoxCardInfoBannerMessageKind.OffloadDevBoxMessage
                ? MessageBarType.severeWarning
                : MessageBarType.info;

        const infoBannerMessage = (
            <DevBoxBannerInfoMessage
                kind={infoBannerKind}
                ariaLabel={spinnerAriaLabel}
                devBoxActionState={devBoxActionState}
            />
        );

        // For info banners, we should open the customization details panel only if there's a customization operation in progress
        if (infoBannerKind === DevBoxCardInfoBannerMessageKind.CustomizeOperationInProgressMessage) {
            return {
                kind: ResourceCardBannerKind.Info,
                isDismissable,
                bannerMessage: infoBannerMessage,
                onDismiss,
                onSeeDetails: onSeeCustomizationDetails,
                iconProps,
                messageBarType,
            };
        }

        return {
            kind: ResourceCardBannerKind.Info,
            isDismissable,
            bannerMessage: infoBannerMessage,
            onDismiss,
            iconProps,
            messageBarType,
        };
    }

    return undefined;
};

export const shouldDisplayCustomizationFailure = (
    status: CustomizationGroupStatus | undefined,
    latestCustomizationGroupFailureEndTime: Date | undefined
): boolean => {
    const ignoreCustomizationFailureDate = new Date();
    ignoreCustomizationFailureDate.setDate(ignoreCustomizationFailureDate.getDate() - CustomizationFailureRetainDays);

    if (
        isFailedCustomizationGroupStatus(status) &&
        latestCustomizationGroupFailureEndTime &&
        latestCustomizationGroupFailureEndTime > ignoreCustomizationFailureDate
    ) {
        return true;
    }

    return false;
};

/** first dev box card + popover should not be already dismissed and skipped +
    dev box card content is ready + connect via app teach bubble target element exists +
    welcome tour completed or skipped or partially seen **/
export const shouldShowFirstTeachingPopoverForWelcomeTour = (
    shouldShowConnectViaAppTeachingPopoverForWelcomeTour: boolean,
    isConnectViaAppTeachingPopoverForWelcomeTourDismissed: boolean,
    isConnectViaAppTeachingPopoverForWelcomeTourSkipped: boolean,
    isCardContentReady: boolean,
    connectViaAppTeachBubbleTargetElement: Element | null,
    isWelcomeTourCompletedOrSkipped: boolean,
    isWelcomeTourPartiallySeen: boolean
): boolean => {
    return (
        (isWelcomeTourCompletedOrSkipped || isWelcomeTourPartiallySeen) &&
        shouldShowConnectViaAppTeachingPopoverForWelcomeTour &&
        !isConnectViaAppTeachingPopoverForWelcomeTourDismissed &&
        !isConnectViaAppTeachingPopoverForWelcomeTourSkipped &&
        isCardContentReady &&
        !!connectViaAppTeachBubbleTargetElement
    );
};

/** when first popover is not shown  + first dev box card +
    popover should not be already dismissed + popupover element exists +
    dev box card content is ready + welcome tour completed or skipped or partially seen **/
export const shouldShowSecondTeachingPopoverForWelcomeTour = (
    shouldShowFirstTeachingPopoverForWelcomeTour: boolean,
    shouldShowDevboxSecondaryActionsTeachingPopoverForWelcomeTour: boolean,
    isDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed: boolean,
    devBoxCardSecondaryActionsTargetElement: Element | null,
    isCardContentReady: boolean,
    isWelcomeTourCompletedOrSkipped: boolean,
    isWelcomeTourPartiallySeen: boolean
): boolean => {
    return (
        (isWelcomeTourCompletedOrSkipped || isWelcomeTourPartiallySeen) &&
        !shouldShowFirstTeachingPopoverForWelcomeTour &&
        shouldShowDevboxSecondaryActionsTeachingPopoverForWelcomeTour &&
        !isDevboxSecondaryActionsTeachingPopoverForWelcomeTourDismissed &&
        !!devBoxCardSecondaryActionsTargetElement &&
        isCardContentReady
    );
};

/** If users haven't completed or skipped the tour or haven't partially seen the tour, continue showing teaching bubble by checking for conditions
 * If users have completed or skipped the tour, do not show this at any point as they are net new users**/
export const showConnectViaAppTeachingBubble = (
    isCardContentReady: boolean,
    shouldShowConnectViaAppTeachBubble: boolean,
    isConnectViaAppTeachBubbleDismissed: boolean,
    connectViaAppTeachBubbleTargetElement: Element | null,
    isWelcomeTourCompletedOrSkipped: boolean,
    isWelcomeTourPartiallySeen: boolean
): boolean => {
    if (!isWelcomeTourCompletedOrSkipped && !isWelcomeTourPartiallySeen) {
        return (
            isCardContentReady &&
            shouldShowConnectViaAppTeachBubble &&
            !isConnectViaAppTeachBubbleDismissed &&
            !!connectViaAppTeachBubbleTargetElement
        );
    }
    return false;
};

export const getNumberDaysUntilScheduledDelete = (devBoxActionState: DevBoxActionState): number | undefined => {
    const { scheduledDeleteTime } = devBoxActionState;

    if (scheduledDeleteTime) {
        const currentTime = new Date();

        if (scheduledDeleteTime > currentTime) {
            return getDaysBetweenDates(scheduledDeleteTime, currentTime);
        } else {
            return -1;
        }
    }

    return undefined;
};

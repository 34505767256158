import {
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    FluentProvider,
    Label,
    makeStyles,
    mergeClasses,
    typographyStyles,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { FormattedDate, FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useEnvironmentDetailsPanelContext } from '../../../hooks/context/panels';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';

export interface EnvironmentDetailsPanelProps {
    isOpen: boolean;
    name: string;
    devCenterName: string;
    projectName: string;
    catalogName: string;
    environmentDefinitionName: string;
    expirationDate?: Date;
    onDismiss: () => void;
}

const messages = defineMessages({
    environmentDetailsPanelCloseButtonLabel: {
        id: 'EnvironmentDetails_Panel_Close_Label',
        defaultMessage: 'Close environment details panel',
        description: 'Aria label for the environment details panel close button label',
    },
});

/**
 * Styles
 */

const useContentStyles = makeStyles({
    root: {
        gap: '31px',
    },
});

const useItemStyles = makeStyles({
    root: {
        gap: '11px',
    },
});

const useTextStyles = makeStyles({
    name: typographyStyles.subtitle2,
    text: typographyStyles.body1Strong,
});

/**
 * END Styles
 */

const EnvironmentDetailsPanelComponent: React.FC<EnvironmentDetailsPanelProps> = (
    props: EnvironmentDetailsPanelProps
) => {
    const {
        isOpen,
        onDismiss,
        name,
        devCenterName,
        projectName,
        catalogName,
        environmentDefinitionName,
        expirationDate,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const styles = useStackStyles();
    const contentStyles = useContentStyles();
    const itemStyles = useItemStyles();
    const textStyles = useTextStyles();

    return (
        <FluentProvider theme={theme}>
            <Drawer open={isOpen} position="end" onOpenChange={onDismiss}>
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label={formatMessage(messages.environmentDetailsPanelCloseButtonLabel)}
                                icon={<Dismiss24Regular />}
                                onClick={onDismiss}
                            />
                        }
                    >
                        <FormattedMessage
                            id="EnvironmentDetails_Panel_Header_Text"
                            defaultMessage="Environment details"
                            description="Text for the environment details panel header."
                        />
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className={mergeClasses(styles.root, contentStyles.root)}>
                        <Label weight="semibold">
                            <div className={textStyles.name}>{name}</div>
                        </Label>
                        <Divider />
                        {expirationDate && (
                            <div className={styles.item}>
                                <div className={mergeClasses(styles.root, itemStyles.root)}>
                                    <div className={mergeClasses(styles.item, textStyles.text)}>
                                        <Label>
                                            <FormattedMessage
                                                id="EnvironmentDetails_Panel_Expiration_Submenu_Title"
                                                defaultMessage="Expiration"
                                                description="Text for the environment details panel created on submenu title"
                                            />
                                        </Label>
                                    </div>
                                    <div className={styles.item}>
                                        <FormattedDate
                                            value={expirationDate}
                                            year="numeric"
                                            month="short"
                                            day="numeric"
                                            hour="numeric"
                                            minute="numeric"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={styles.item}>
                            <div className={mergeClasses(styles.root, itemStyles.root)}>
                                <div className={mergeClasses(styles.item, textStyles.text)}>
                                    <Label>
                                        <FormattedMessage
                                            id="EnvironmentDetails_Panel_Catalog_Submenu_Title"
                                            defaultMessage="Catalog"
                                            description="Text for the environment details panel catalog submenu title"
                                        />
                                    </Label>
                                </div>
                                <div className={styles.item}>{catalogName}</div>
                                <div className={mergeClasses(styles.item, textStyles.text)}>
                                    <Label>
                                        <FormattedMessage
                                            id="EnvironmentDetails_Panel_Definition_Submenu_Title"
                                            defaultMessage="Definition"
                                            description="Text for the environment details panel definition submenu title"
                                        />
                                    </Label>
                                </div>
                                <div className={styles.item}>{environmentDefinitionName}</div>
                                <div className={mergeClasses(styles.item, textStyles.text)}>
                                    <Label>
                                        <FormattedMessage
                                            id="EnvironmentDetails_Panel_DevCenter_Submenu_Title"
                                            defaultMessage="Dev center"
                                            description="Text for the environment details panel dev center submenu title"
                                        />
                                    </Label>
                                </div>
                                <div className={styles.item}>{devCenterName}</div>
                                <div className={mergeClasses(styles.item, textStyles.text)}>
                                    <Label>
                                        <FormattedMessage
                                            id="EnvironmentDetails_Panel_Project_Submenu_Title"
                                            defaultMessage="Project"
                                            description="Text for the environment details panel project submenu title"
                                        />
                                    </Label>
                                </div>
                                <div className={styles.item}>{projectName}</div>
                            </div>
                        </div>
                    </div>
                </DrawerBody>
            </Drawer>
        </FluentProvider>
    );
};

const EnvironmentDetailsPanel: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useEnvironmentDetailsPanelContext();

    const onDismiss = React.useCallback(() => closePanel(), [closePanel]);

    return <EnvironmentDetailsPanelComponent {...properties} isOpen={isOpen} onDismiss={onDismiss} />;
};

export const EnvironmentDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { isOpen } = useEnvironmentDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <EnvironmentDetailsPanel />;
};

export default EnvironmentDetailsPanelContainer;
